<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="3"
          class="mb-2"
        >
          <h3>Account number or email:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by account number or email"
                @keyup.enter="advancedFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="advancedFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <h3>Types:</h3>
          <b-form-group
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              variant="light-warning"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                v-model="advancedFilter.haveToApprove"
                class="vh-center"
              >
                <span class="text-warning">Have to be approve</span>
              </b-form-checkbox>
            </b-badge>
            <b-badge
              variant="light-danger"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                v-model="advancedFilter.isDeclined"
                class="vh-center"
              >
                <span class="text-danger">User decline</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-group>
        </b-col>

        <b-col lg="3">
          <h3>States:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="advancedFilter.state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(value, key, index) in consentStateVariants"
              :key="index"
              :variant="`light-${value}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${value}`">{{ key }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>

    <data-table
      ref="consentsTable"
      :module-name="'contractConsentsList'"
      :fields="fields"
      :filters="advancedFilter"
      :actions="actions"
      sort-by="files"
      :sort-desc="true"
      deletable
      @edit="onEdit"
      @restore="onRestore"
      @restoreDecision="onRestoreDecision"
      @stateInvestigationContacted="onInvestigationContacted"
      @stateInvestigationDeclined="onInvestigationDeclined"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.account.user.id } }">
              {{ data.item.account.user.firstName }} {{ data.item.account.user.lastName }}
              <risk-score-badge
                v-if="data.item.account.user.risk_score"
                class="mx-1"
                :score-results="data.item.account.user.risk_score_results"
                :score="data.item.account.user.risk_score"
              />
              <br>
              <small>{{ data.item.account.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.user.tags"
              :extras-tags="data.item.account.user.kyc_at ? [ `Verified ${formatDateTime(data.item.account.user.kyc_at)}`] : []"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(country)="data">
        {{ data.item.account.user.country.name }}
      </template>

      <template #cell(account)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
              {{ data.item.account.account_id }} ({{ accountTypes[data.item.account.account_type_name] }} - {{ data.item.account.server.platform.id }})
            </b-link>
            <risk-score-badge
              v-if="data.item.account.risk_score"
              class="mx-1"
              :score-results="data.item.account.risk_score_results"
              :score="data.item.account.risk_score"
            />
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.tags"
              :extras-tags="data.item.account.extras_tags"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(rules)="data">
        {{ data.item.account.rule_max_daily_drawdown }}%|{{ data.item.account.rule_max_total_drawdown }}%|{{ data.item.account.rule_min_trading_days }}|{{ data.item.account.rule_first_withdrawal_after }}|{{ data.item.account.rule_withdrawal_after }} - {{ ddTypes.find(rule => rule.value === data.item.account.rule_drawdown_type).label }} -

        <span
          v-for="(item, key) in data.item.account.special_options"
          :key="key"
        >
          {{ item }}
        </span>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.account.deposit, data.item.account.currency.label) }}
      </template>

      <template #cell(acceptedAt)="data">
        <b-badge
          v-if="data.item.acceptedAt"
          variant="light-success"
        >
          {{ formatDateTime(data.item.acceptedAt) }}
        </b-badge>
      </template>

      <template #cell(declinedAt)="data">
        <b-badge
          v-if="data.item.declinedAt"
          variant="light-danger"
        >
          {{ formatDateTime(data.item.declinedAt) }}
        </b-badge>
      </template>

      <template #cell(doc_expiration)="data">
        {{ formatDate(data.item.doc_expiration) }}
      </template>

      <template #cell(supportApprovedAt)="data">
        <b-badge
          v-if="data.item.supportApprovedAt"
          variant="light-info"
        >
          {{ formatDateTime(data.item.supportApprovedAt) }}
        </b-badge>
        <b-badge
          v-else-if="(data.item.files !== null || data.item.approval_required) && data.item.supportApprovedAt === null"
          variant="light-danger"
        >
          Have to approve
        </b-badge>
      </template>

      <template #cell(state)="data">
        <b-badge
          v-if="data.item.state !== 'null'"
          :variant="`light-${consentStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>
        <b-button
          v-if="!data.item.supportApprovedAt"
          v-b-tooltip.hover.left="'Approve consent'"
          variant="outline-success"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'approved by support')"
        >
          <feather-icon icon="UserCheckIcon" />
        </b-button>
        <b-button
          v-if="!data.item.supportApprovedAt"
          v-b-tooltip.hover.left="'Change state to INVESTIGATION'"
          variant="outline-warning"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'investigation')"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </template>

    </data-table>
    <b-modal
      id="consentModal"
      title="Edit contract consent"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <contract-consent-form
        :consent="consentEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import { dispatch } from 'vuex-pathify'
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BBadge,
  BCard,
  BLink,
  BModal,
  BFormCheckbox, VBTooltip, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTime, formatCurrency, formatDate } from '@/plugins/formaters'
import { CONSENT_STATE_VARIANTS } from '@/enums/consents'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import table from '@/mixins/table'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import RowDetail from './components/RowDetail.vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import TagsList from '@/components/page/TagsList.vue'
import ContractConsentForm from '@/components/forms/contract-consent/ContractConsentForm.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import store from '@/store'
import ConsentAPI from '@/api/contract-consent'
import { ACCOUNTS_SHORT, DRAWDOWN_TYPES_ENUM } from '@/enums/accounts'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Restore decision',
    icon: 'RefreshCwIcon',
    emit: 'restore',
  },
  {
    text: 'Restore decision and request new files',
    icon: 'FileMinusIcon',
    emit: 'restoreDecision',
  },
  {
    text: 'Investigation - CONTACTED',
    icon: 'XIcon',
    emit: 'stateInvestigationContacted',
  },
  {
    text: 'Investigation - DECLINED',
    icon: 'XIcon',
    emit: 'stateInvestigationDeclined',
  },
]

const fields = [
  'show_details',
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'country',
    sortable: false,
  },
  {
    key: 'account',
    sortable: false,
  },
  {
    key: 'rules',
    sortable: false,
    label: 'ID|DD|TD|FW|W',
  },
  {
    key: 'deposit',
    sortable: false,
  },
  {
    key: 'acceptedAt',
    sortable: true,
  },
  {
    key: 'declinedAt',
    sortable: true,
  },
  {
    key: 'supportApprovedAt',
    sortable: true,
  },
  {
    key: 'ip',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'actions',
    sortable: false,
  },
]

export default {
  components: {
    BFormCheckboxGroup,
    ContractConsentForm,
    DataTable,
    ShowDetails,
    TagsList,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BCol,
    BRow,
    BBadge,
    BCard,
    RowDetail,
    BLink,
    BModal,
    RiskScoreBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [table],
  data() {
    return {
      fields,
      accountTypes: ACCOUNTS_SHORT,
      ddTypes: DRAWDOWN_TYPES_ENUM,
      advancedFilter: {
        isDeclined: true,
        search: '',
        haveToApprove: true,
        state: [],
      },
      search: '',
      actions,
      accountEdit: null,
      consentEdit: null,
      consentStateVariants: CONSENT_STATE_VARIANTS,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    formatDate,
    onEdit(item) {
      dispatch('contractConsentsList/get', item.id).then(data => {
        this.consentEdit = data
        this.$bvModal.show('consentModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('consentModal')
    },
    onRestoreDecision(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Consent decision will be restored and user will have to accept or decline and upload new files again. ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset!',
      }).then(result => {
        if (result.isConfirmed) {
          ConsentAPI.postRestoreDecision(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Decision was restored successfully',
                  icon: 'WarningIcon',
                  variant: 'success',
                },
              })
              this.$refs.consentsTable.reloadAll()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Request failed',
                  icon: 'WarningIcon',
                  text: error.data.detail,
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onRestore(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Consent decision will be restored and user will have to accept or decline again. ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('contractConsentsList/update', {
            id: item.id,
            data: {
              declinedAt: null, acceptedAt: null, supportApprovedAt: null, files: null,
            },
          })
        }
      })
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
    onChangeState(item, state) {
      ConsentAPI.patchConsent(item.id, { state }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'State was updated successfully',
            icon: 'WarningIcon',
            variant: 'success',
          },
        })
        this.$refs.consentsTable.reloadAll()
      })
    },
    onInvestigationContacted(item) {
      this.onChangeState(item, 'investigation - contacted')
    },
    onInvestigationDeclined(item) {
      this.onChangeState(item, 'investigation - declined')
    },
  },
}
</script>
